import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Hidden, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './SignupConfirmation.styles';
import ReactCodeInput from 'react-code-input';
import TransitionAlert from '../Common/Alert';
import APIProspect from '../../services/APIProspect.service';
import { Helmet } from 'react-helmet';
import NavBarV2 from '../Header/NavBarV2';
import { useAuth } from '../../hooks/useAuth';
import { navigate } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const SignUpConfirmation = (props: any) => {
  const [submit, setSubmit] = React.useState<boolean>(false);
  const classes = useStyles();
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState('');
  const [btnIsPressed, setBtnIsPressed] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [time, setTime] = React.useState(59);
  const { login } = useAuth();
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    setBtnIsPressed(true);
  };
  const tick = () => {
    if (time === 0) {
      if (typeof window !== `undefined`) {
        navigate('/register/');
      }
    } else {
      setTime(time - 1);
    }
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const handleSubmit = async (e) => {
    setSubmit(true);
    e.preventDefault();
    const data = { email: props.email, code: pinCode };
    await APIProspect.post('signup/confirmation', data)
      .then(async (response) => {
        const token = response.data;
        if (typeof window !== `undefined`) {
          window.localStorage.setItem('bearer', JSON.stringify(token));
          await login(response.data, true, true);
        }
      })
      .catch((error) => {
        setSubmit(false);
        setAlertOpen(true);
        setAlertTitle('El código ingresado es incorrecto.');
      });
  };

  return (
    <>
      <section id="registerPage" className={classes.root}>
        <NavBarV2 />
        <Helmet bodyAttributes={{ class: 'registerPage' }} />
        <Container className={classes.container} maxWidth={false}>
          <Hidden smDown>
            <Grid item={true} md={6} className={classes.feather} />
          </Hidden>
          <Grid item={true} className={classes.formGroup} xs={12} md={6} lg={4}>
            <form onSubmit={handleSubmit} className={classes.inputGroup}>
              <Grid item={true} className={classes.formVerification}>
                <Typography component="h1" variant="h5">
                  Ingresa el código de verificación
                </Typography>
                <p>
                  Lo enviamos a tu correo electrónico desde la dirección noreply@currencybird.cl.
                </p>{' '}
                <p>{`00:${time.toString().padStart(2, '0')}`}</p>
                <Grid item={true} id="codeInput" xs={12}>
                  <ReactCodeInput
                    id="reactCodeInput"
                    type={isMobile ? 'number' : 'text'}
                    isValid={isPinCodeValid}
                    fields={6}
                    onChange={handlePinChange}
                    value={pinCode}
                  />
                </Grid>
                <Grid item className={classes.submitGroup} xs={8} md={8} lg={8}>
                  <Button
                    fullWidth
                    startIcon={submit ? <CircularProgress size="1rem" /> : null}
                    disabled={submit}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Enviar
                  </Button>
                </Grid>
                <Grid className={classes.resend} item xs={12}>
                  ¿No recibiste nuestro correo?
                  <br />
                  <a href="/signup/resend">Reenviar código</a>
                  <br />
                </Grid>
                <Grid className={classes.backTo} item xs={12}>
                  ¿Ya estás registrado?
                  <a href={`${process.env.GATSBY_URL_NEW_FRONT}/login`}> Inicia sesión</a>
                </Grid>
                <Grid item xs={12}>
                  {/* {!isPinCodeValid && btnIsPressed && ( */}
                  <TransitionAlert
                    setAlertOpen={setAlertOpen}
                    open={alertOpen}
                    title={alertTitle}
                    description="Intente nuevamente"
                    severity="error"
                  />
                  {/* )} */}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default SignUpConfirmation;
