import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO';
import Confirmation from '../../components/Registration/SignUpConfirmation';
import { navigate } from 'gatsby';

const SignUpConfirmation = ({ location }: { location: any }) => {
  const locationState = location.state;

  useEffect(() => {
    if (locationState == null) {
      navigate('/');
    }
  }, []);

  return (
    <>
      {locationState && (
        <>
          <Layout>
            <SEO title="Confirma tu código" />
            <Confirmation email={location.state.values.email} />
          </Layout>
        </>
      )}
    </>
  );
};

export default SignUpConfirmation;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
